import React from "react";

function initialState() {
  return {
    loader: {
      isHidden: true,
      images: [],
      hideText: true,
    },
    toast: {
      message: "",
      delay: 5000,
    },
    modals: [],
  };
}

const AppContext = React.createContext(initialState());

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState();
  }

  render() {
    const state = {
      ...this.state,
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      hideLoader: (cb) => {
        const nextState = this.state.loader;
        nextState.isHidden = true;

        this.setState({loader: nextState}, () => cb && cb());
      },
      setLoaderImages: (images = [], cb) => {
        this.setState({
          loader: {
            ...this.state.loader,
            images,
          },
        }, () => cb && cb());
      },
      showLoader: (showTexts = true, cb) => {
        this.setState({
          loader: {
            ...this.state.loader,
            isHidden: false,
            hideText: !showTexts,
          }
        }, () => cb && cb());
      },
      showToast: ({message, delay}) => {
        this.setState({
          toast: {
            messageKey: Date.now(),
            message,
            delay,
          },
        });
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
