import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import {debounce} from "./utils/etc";
import {preloadNativeAds, resetNativeAdsCounter} from "./utils/native-ads";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import RouteChangeListener from "./components/RouteChangeListener";
import AppToast from "./components/AppToast";
import {withRouter} from "react-router";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

const LazyIndexPage = React.lazy(() => import("./pages/IndexPage"));
const LazyResultPage = React.lazy(() => import("./pages/ResultPage"));
const LazyProcessingPage = React.lazy(() => import("./pages/ProcessingPage"));
const LazyUploadPage = React.lazy(() => import("./pages/UploadPage"));

class App extends React.Component {

  componentDidMount() {
    resetNativeAdsCounter();

    window.webviewEventsListeners.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  handleWebviewTabSelected = () => {
    debounce("App.handleWebviewTabSelected.preloadAds", 300, () => {
      preloadNativeAds();
    });
  };

  render() {
    return (
      <React.Fragment>
        <RouteChangeListener onChanged={() => window.scrollTo(0, 0)} />
        <WindowSizeWatcher />
        <AppToast {...this.context.toast} />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          <Suspense fallback={<div />}>
            <Switch>
              <Route exact path={routes.INDEX} render={(props) => <LazyIndexPage {...props} />} />
              <Route exact path={routes.UPLOAD} render={(props) => <LazyUploadPage {...props} />} />
              <Route exact path={routes.PROCESSING} render={(props) => <LazyProcessingPage {...props} />} />
              <Route exact path={routes.RESULT} render={(props) => <LazyResultPage {...props} />} />
            </Switch>
          </Suspense>
          <ModalWrapper />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContextProvider>
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  </AppContextProvider>
);